import React from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { fetchMonth } from "./helper";

class DoubleBarChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSets: [],
      labelArray: []
    };
  }

  componentDidMount() {
    this.processData(this.props?.info || []);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.info, this.props.info)) {
      this.processData(this.props?.info || []);
    }
  }
  processData = (info) => {
    const sortedArray = _.sortBy(info, 'team');
    const labelArray = _.uniq(sortedArray.map(element => element.team));
    const groupByMonth = _.groupBy(sortedArray, 'month');

    // Default colors for the datasets
    const defaultColors = [
      { // Default color for legend 1
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: 'rgba(255, 99, 132, 1)'
      },
      { // Default color for legend 2
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)'
      },
      // { // Default color for legend 2
      //   backgroundColor: 'rgba(255, 243, 200, 0.5)',
      //   borderColor: 'rgba(254, 231, 176, 1)'
      // },
      // { // Default color for legend 2
      //   backgroundColor: 'rgba(205, 235, 255,0.5)',
      //   borderColor: 'rgba(162, 218, 255,1)'
      // }
      // Add more colors as needed
    ];

    // Reverse the default colors array
    const reversedDefaultColors = defaultColors.slice().reverse();

    const dataSets = _.map(groupByMonth, (group, month) => {
      // Assign colors from reversed array
      const colorIndex = Object.keys(groupByMonth).length - 1 - Object.keys(groupByMonth).indexOf(month);
      const color = reversedDefaultColors[colorIndex % reversedDefaultColors.length];

      return {
        fill: true,
        label: fetchMonth(Number(moment(month, "YYYYM").format("M"))),
        data: group.map(val => val.utilization),
        borderWidth: 1,
        backgroundColor: color.backgroundColor,
        borderColor: color.borderColor,
      };
    });

    this.setState({ dataSets, labelArray });
    this.options = {
      plugins: {
        title: {
          display: true,
          text: 'Last Month VS This Month',
          align: 'start',
          font: {
            size: 16,
            color: '#212529',
            weight: '400',
            family: '"Segoe UI", Roboto',
          },
          padding: {
            bottom: -24,
            top: 5,
          }
        },
        legend: {
          display: true,
          position: 'top',
          align: 'end',
          labels: {
            flow: 'left',
            boxWidth: 20,
            boxHeight: 15,
            font: {
              size: 16,
              color: '#212529',
              weight: '400',
              family: '"Segoe UI", Roboto'
            }
          }
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem, data) {
              return tooltipItem.dataset.label + ": " + (tooltipItem.formattedValue)
            },
          }
        }
      },
      responsive: true,
      scales: {
        x: {
          grid: {
            color: function (context) {
              return 'rgba(0, 0, 0, 0.1)';
            },
          },
          ticks: {
            maxRotation: 90,
            minRotation: 90,
            padding: 10,
            autoSkip: false,
            fontSize: 13
          }
        },
        y: {
          ticks: {
            autoSkip: false,
            callback: (value, index, values) => {
              return value;
            },
            tickLength: 20 // Change the value as needed
          }
        }
      },
      maintainAspectRatio: false,
    };
  };

  render() {
    const { dataSets, labelArray } = this.state;
    const dataVal = {
      labels: labelArray,
      datasets: dataSets
    };

    return (
      <div id="bar-chart" className="ratio ratio-1x1">
        <Bar options={this.options} data={dataVal} />
      </div>
    );
  }
};

DoubleBarChartComponent.propTypes = {
  info: PropTypes.array.isRequired, // Validate history prop
};

export default DoubleBarChartComponent;
