
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Chart from "../../CustomerChart";
import * as routes from "../../../constants/routes";
class CustomerUtilization extends Component {
  constructor(props) {
    super(props); 
    if (!sessionStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken") === '') {
      this.props.history.push(routes.LOGIN);
    }

    const currentDate = moment();
    this.state = {
      selectedDate: {
        startDate: moment(currentDate).startOf('month').toDate(),
        endDate: moment(currentDate).endOf('month').toDate()
      }
    }
  }

  componentDidMount() {
    const { currentUser } = this.props;
    if (!currentUser || Object.keys(currentUser).length === 0) {
      window.location.href = '/';
    }
  }

  handleDate(changeDateVal, dateKey) {
    this.setState(prevState => ({
      selectedDate: {
        ...prevState.selectedDate,
        [dateKey]: changeDateVal
      }
    }));
  }

  render() {
    
    return (
      <div className="flex-grow-1 pb-5 px-3">
        <div className="text-center mt-3">
          <img src="/images/logo-symbol.png" className="" alt="send" />
          <h1 className="font-24 font-blue fw-bold my-2">BlattnerTech India</h1>
          <h2 className="font-32 text-dark fw-bold my-2">Usage Report</h2>
        </div>
        <Container fluid className="mt-4 w-100 max-w-896 mx-auto">
          {this.state.selectedDate.startDate && this.state.selectedDate.endDate && 
              <Form className="mb-3 row mx-auto max-w-568 w-100 border rounded-8 py-2 align-items-center mb-4" onSubmit={(e) => {e.preventDefault();}}>
                <div className="d-flex col-12 col-md-6 align-items-center mb-2 mb-md-0">
                  <div className="date-picker-label me-2">Start Date:</div>
                  <DatePicker 
                    className={"datepicker flex-grow-1"}
                    onChange={(val) => this.handleDate(val, 'startDate')} 
                    value={this.state.selectedDate.startDate} 
                    clearIcon="" 
                    dayPlaceholder="DD" 
                    monthPlaceholder="MM" 
                    yearPlaceholder="YYYY" 
                    maxDate={this.state.selectedDate.endDate} // set max date based on end date
                    onKeyDown={(e) => e.preventDefault()}
                  />
                </div>
                <div className="d-flex col-12 col-md-6 align-items-center">
                  <div className="date-picker-label me-2">End Date:</div>
                  <DatePicker 
                    className={"datepicker flex-grow-1"}
                    onChange={(val) => this.handleDate(val, 'endDate')} 
                    value={this.state.selectedDate.endDate} 
                    clearIcon="" 
                    dayPlaceholder="DD" 
                    monthPlaceholder="MM" 
                    yearPlaceholder="YYYY" 
                    minDate={this.state.selectedDate.startDate} // set min date based on start date
                    onKeyDown={(e) => e.preventDefault()}
                  />
                </div>
              </Form>
          }
        </Container>
        <div className="container">
          <Chart dateInfo={this.state.selectedDate} />
        </div>
      </div>
    );
  }
}

CustomerUtilization.propTypes = {
  history: PropTypes.object.isRequired, // Validate history prop
  currentUser: PropTypes.object.isRequired, // Validate currentUser prop
};

function mapStateToProps(state) {
  return {
    currentUser: state.security.user,
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerUtilization);