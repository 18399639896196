
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import * as routes from "../../../constants/routes";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

class QueryQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {      
      question: ''
    };
    if (!sessionStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken") === '') {
      this.props.history.push(routes.LOGIN);
    }
  }

  componentDidMount() {    
    if (this.props.queryQuestion) {
      this.setState({
        question: this.props.queryQuestion
      });
    }
  }

  componentDidUpdate(prevProps) {
    
    if (this.props.queryQuestion && prevProps.queryQuestion !== this.props.queryQuestion && this.props.isViewHistory==='0') {
      this.setState({
        question: this.props.queryQuestion
      });
    }
  }

  render() {

    return (
      <div className=" w-100 max-w-896 mx-auto container-fluid pt-5">
        {this.state.question && this.state.question !== '' ?
          <div className="clear">{this.state.question}</div> : ''
        }
      </div>
    );
  }
}

QueryQuestion.propTypes = {
  question:PropTypes.object.isRequired,
  queryQuestion:PropTypes.object.isRequired,
  isViewHistory:PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    
    queryQuestion: state.queryBox.queryQuestion,
    isViewHistory: state.queryBox.isViewHistory
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {

    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(QueryQuestion);