
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { askFunnelQuestion, setFunnelQuestion } from "../../../actions/index";
import * as routes from "../../../constants/routes";
import Progress from "react-progress-2";
import Result from '../Result/Result';
class FunnelBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workStartDate: new Date(),
      WorkStartDateHelperText: "",
      results: {},
      question: "",
      askedQuestion: "",
    };
    if (!sessionStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken") === '') {
      this.props.history.push(routes.LOGIN);
    }

  }

  componentDidMount() {

    let currentUser = this.props.currentUser;
    if (currentUser === '' || Object.keys(currentUser).length === 0) {
      window.location.href = '/';
    }

  }

  componentDidUpdate(prevProps) {

    if (this.props.funnelResult && prevProps.funnelResult !== this.props.funnelResult) {
      this.setState({       
        results: this.props.funnelResult,
        question: ''
      });
    }
    if (this.props.funnelQuestion && prevProps.funnelQuestion !== this.props.funnelQuestion) {
      this.setState({
        askedQuestion: this.props.funnelQuestion
      });
    }
  }  
  handleKeyDown(event) {
    if (event.key === 'Enter') {
      this.sendQuestion();
    }
  }

  onChangeValueHandler = (e) => {
    let value = e.target.value;
    this.setState({
      [e.target.id]: value,
    });
  };
  sendQuestion = () => {   

    let question = this.state.question;
    if (question && question !== '') {
      question = question.trim();
    }
    let token = this.state.token;

    if (!Progress.isVisible() && question !== '') {
      this.setState({
        results: {
          records: [],
          columns: {}
        }
      });
      this.props.setFunnelQuestion(question);
      this.props.askFunnelQuestion(question, token);
    }

  }

  render() {

    return (
      <div className="flex-grow-1 pb-5 px-3">
        <div className="text-center mt-3">
          <img src="/images/logo-symbol.png" className="" alt="send" />
          <h1 className="font-24 font-blue fw-bold my-2">BlattnerTech India</h1>
          <h2 className="font-32 text-dark fw-bold my-2">Funnel Data Retriever Engine</h2>
          <p className="fw-semibold font-16 text-muted">Identify BlattnerTech India team members by skillset and project availability</p>
        </div>

        <div className="w-100 max-w-896 mx-auto">
          {this.state.WorkStartDateHelperText}
          <div className="input-group mb-3 searchbox rounded-6 mt-2 mb-3">
            <input type="" id="question" value={this.state.question} className="form-control bg-transparent border-0" placeholder="Make a funnel data request" onChange={(e) => this.onChangeValueHandler(e)} onKeyDown={(e) => this.handleKeyDown(e)} autoComplete="off" />
            <button className="input-group-text enter-text-button bg-transparent border-0" disabled={!this.state.question} onClick={() => this.sendQuestion()}>
              <img src="/images/send_black_dark.png" className="" alt="send" />
            </button>
          </div>
          {Object.values(this.state.results).length > 0 &&
            <div className="resulttable">
              <Result question={this.state.askedQuestion} results={this.state.results}></Result>
            </div>
          }
        </div>
      </div>
    );
  }
}

FunnelBoard.propTypes = {
  funnelResult: PropTypes.object, // Validate history prop
  history: PropTypes.object.isRequired, // Validate history prop
  currentUser: PropTypes.object.isRequired, // Validate currentUser prop
  funnelQuestion: PropTypes.string,
  setFunnelQuestion: PropTypes.func,
  askFunnelQuestion: PropTypes.func
};

function mapStateToProps(state) {
  return {

    currentUser: state.security.user,
    funnelResult: state.dashboard.funnelResult,
    funnelQuestion: state.dashboard.funnelQuestion,
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      setFunnelQuestion,
      askFunnelQuestion
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(FunnelBoard);