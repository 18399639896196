
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as routes from "../../../constants/routes";
import PropTypes from 'prop-types';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { TypeAnimation } from 'react-type-animation';
import Loader from "react-js-loader";
class QueryResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryResult: '',
      queryQuestion: '',
      answer: '',
      showQuestionLoader: false
    };
    if (!sessionStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken") === '') {
      this.props.history.push(routes.LOGIN);
    }
  }

  componentDidMount() {
    if (this.props.queryResult) {
      this.setState({
        queryResult: this.props.queryResult,
        queryQuestion: this.props.queryQuestion
      });
    }
    if (this.props.answer) {
      this.setState({
        answer: this.props.answer
      });
    }
    console.log(" this.props.isLastIndex:::", this.props.queryResult);
  }

  componentDidUpdate(prevProps) {
    if (this.props.queryResult && prevProps.queryResult !== this.props.queryResult) {
      this.setState({
        queryResult: this.props.queryResult
      });
    }

    if (this.props.showQuestionLoader && prevProps.showQuestionLoader !== this.props.showQuestionLoader) {      
      this.setState({
        showQuestionLoader: this.props.showQuestionLoader
      });
    }
  }

  render() {

    return (
      <div className="flex-grow-1 pb-5 px-3">
        <div className="text-center mt-3">
          <div className="max-w-768 mx-auto w-full px-2 mb-2 mt-auto">
            {this.state.showQuestionLoader &&
              <div className={"item"}>
                <Loader type="bubble-scale" bgColor={"#000"} color={"#000"} title={""} size={100} />
              </div>
            }
            {this.state.queryResult && this.state.queryResult !== '' ?
              <div>
                <TypeAnimation
                  sequence={[
                    // Same substring at the start will only be typed out once, initially
                    this.state.queryResult,
                    3000, // wait 1s before replacing "Mice" with "Hamsters"                 
                  ]}
                  wrapper="span"
                  speed={60}

                  repeat={Infinity}
                  cursor={false}
                /> </div> : ''}
            {
              this.state.answer && this.state.answer !== '' ?
                <div>{this.state.answer}</div> : ''
            }
          </div>

        </div>
      </div>
    );
  }
}

QueryResult.propTypes = {
  queryResult: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  showQuestionLoader: PropTypes.object.isRequired,
  queryQuestion: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {

    queryResult: state.queryBox.queryResult,
    queryQuestion: state.queryBox.queryQuestion,
    isViewHistory: state.queryBox.isViewHistory,
    showQuestionLoader: state.queryBox.showQuestionLoader,
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {

    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(QueryResult);