
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import * as routes from "../../../constants/routes";
import { askQueryQuestion, setQueryQuestion, viewHistory } from "../../../actions/index";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Progress from "react-progress-2";
import { withRouter } from 'react-router';
class QueryBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      queryResult: {},
      isViewHistory: '0',
      queryQuestion: '',
      historyChat:[]

    };
    if (!sessionStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken") === '') {
      this.props.history.push(routes.LOGIN);
    }
  }


  componentDidUpdate(prevProps) {
    // if (this.props.queryQuestion && prevProps.queryQuestion !== this.props.queryQuestion) {
    //   this.setState({
    //     question: this.props.queryQuestion
    //   });
    // }

    if (this.props.queryResult && prevProps.queryResult !== this.props.queryResult) {

      this.setState({
        queryResult: this.props.queryResult
      });
    }
    if (this.props.isViewHistory && prevProps.isViewHistory !== this.props.isViewHistory) {
      this.setState({
        isViewHistory: this.props.isViewHistory
      });
    }

    if (this.props.queryQuestion && prevProps.queryQuestion !== this.props.queryQuestion) {
      this.setState({
        queryQuestion: this.props.queryQuestion
      });
    }
    if (this.props.historyChat && prevProps.historyChat.length !== this.props.historyChat.length) {      
      this.setState({
        historyChat: this.props.historyChat
      });
    }
  }
  onChangeValueHandler = (e) => {
    let value = e.target.value;
    this.setState({
      [e.target.id]: value,
    });
  };
  sendQuestion = () => {

    let question = this.state.question;
    question = question.trim();    
    if (!Progress.isVisible() && question !== '') {
      //window.location.href = '/chat-history';      
      
      if (window.location.pathname !== '/chat-history') {
        this.props.askQueryQuestion(question,'0');
        this.props.setQueryQuestion(question);
        //history.push(routes.CHATHISTORY);
        this.props.history.push(routes.CHATHISTORY);        
      } else {
        this.props.askQueryQuestion(question, '0');
        this.props.setQueryQuestion(question);
      }
      this.setState({
        question: ''
      });
      setTimeout(() => {
          console.log("this.state.question::::",this.state.question);
      }, 2000);
    }
  }

  viewHistroy = () => {
    if (window.location.pathname !== '/chat-history') {
      this.props.viewHistory();
      this.props.history.push(routes.CHATHISTORY);
      this.setState({
        question: ''
      });
    } else {
      this.props.viewHistory();
    }
  }
  handleKeyDown(event) {
    if (event.key === 'Enter') {
      this.sendQuestion();
    }
  }
  render() {

    return (
      <div className="flex-grow-1 pb-5 px-3">
        <div className="text-center mt-3">
          <div className="max-w-768 mx-auto w-full px-2 mb-2 mt-auto">
            <div className="input-group mb-3 searchbox rounded-6 mt-2 mb-3">
              <input type="" id="question" value={this.state.question} className="form-control bg-transparent border-0" placeholder="Make a funnel data request" onChange={(e) => this.onChangeValueHandler(e)} onKeyDown={(e) => this.handleKeyDown(e)} autoComplete="off" />
              <button className="input-group-text enter-text-button bg-transparent border-0" disabled={!this.state.question} onClick={() => this.sendQuestion()}>
                <img src="/images/send_black_dark.png" className="" alt="send" />
              </button>

              <button className="input-group-text enter-text-button bg-transparent border-0" onClick={() => this.viewHistroy()}>
                View All
              </button>

            </div>
          </div>

        </div>
      </div>
    );
  }
}

QueryBox.propTypes = {
  historyChat: PropTypes.object.isRequired, // Validate history prop
  setQueryQuestion: PropTypes.func,
  askQueryQuestion: PropTypes.func
};

function mapStateToProps(state) {
  return {
    queryQuestion: state.queryBox.queryQuestion,
    queryResult: state.queryBox.queryResult,
    historyChat: state.queryBox.history,
    isViewHistory: state.queryBox.isViewHistory    
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      askQueryQuestion,
      setQueryQuestion,
      viewHistory      
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QueryBox));