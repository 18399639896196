import axios from "axios";
import { alertService } from "../components/GlobalComponent/alertService";
import { API_URL, handleError, FETCH_ASK_RESULTS, SET_QUESTION, ALLOWED_WHITELISTED_DOMAINS } from "../actions/index";
export const FETCH_APPLICATION = "FETCH_APPLICATION";
//Admin user
export const FETCH_PROVIDER = "FETCH_PROVIDER";
export const setQuestion =
  (question) =>
    async (dispatch) => {
      dispatch({
        type: SET_QUESTION,
        payload: question,
      });
    };
export const askResourceQuestion =
  (question, token, workStartDate) =>
    async (dispatch) => {
      try {
        let ask_question_url = `${API_URL}/dashboard/askdataresource`;
        const parsedUrl = new URL(ask_question_url);

        if (ALLOWED_WHITELISTED_DOMAINS.includes(`${parsedUrl.protocol}//${parsedUrl.host}`)) {
          let payload = {
            "question_text": question,
            "work_star_date": workStartDate
          }
          const res = await axios.post(ask_question_url, payload, {
            headers: {
              token: token
            }
          });

          let results = res.data;
          if (results.data === '' && results.message !== '') {
            alertService.error(results.message, { autoClose: true });
            dispatch({
              type: FETCH_ASK_RESULTS,
              payload: '',
            });
          }
          else {
            dispatch({
              type: FETCH_ASK_RESULTS,
              payload: results.data,
            });
          }
        } else {
          alertService.error('Domain not allowed', { autoClose: true });
          const error = new Error('Domain not allowed');
          handleError(error, dispatch);
        }
      } catch (error) {
        handleError(error, dispatch);
      }
    };

export const askUserQuestion =
  (question, token) =>
    async (dispatch) => {
      try {
        let ask_question_url = `${API_URL}/dashboard/askdatauser`;
        const parsedUrl = new URL(ask_question_url);

        if (ALLOWED_WHITELISTED_DOMAINS.includes(`${parsedUrl.protocol}//${parsedUrl.host}`)) {
          let payload = {
            "question_text": question
          }

          const res = await axios.post(ask_question_url, payload, {
            headers: {
              token: token
            }
          });

          let results = res.data;
          if (results.data === '' && results.message !== '') {
            alertService.error(results.message, { autoClose: true });
            dispatch({
              type: FETCH_ASK_RESULTS,
              payload: '',
            });
          }
          else {
            dispatch({
              type: FETCH_ASK_RESULTS,
              payload: results.data,
            });
          }
        } else {
          alertService.error('Domain not allowed', { autoClose: true });
          const error = new Error('Domain not allowed');
          handleError(error, dispatch);
        }
      } catch (error) {
        handleError(error, dispatch);
      }
    };
