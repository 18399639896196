import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import PropTypes from "prop-types";
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment-timezone';
const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});
const DashboardResult = (props) => {
  const [question, setQuestion] = useState('');
  const [columnList, setColumn] = useState('');
  const [data, setData] = useState('');

  const [type, setType] = useState('');
  const [text, setText] = useState('');
  useEffect(() => {
    setQuestion(props.question);
    setColumn(props.results.columns);
    setData(props.results.records);
    setType(props.results.type);
    setText(props.results.text);
  }, [props.question, props.results]);

  const columns = useMemo(
    () =>
      data.length
        ? Object.keys(data[0]).map((columnId) => ({
          header: columnList[columnId].name ?? columnId,
          accessorKey: columnId,
          id: columnId,
          size: columnList[columnId].size
        }))
        : [],
    [columnList, data],
  );
  const handleExportData = () => {
    csvConfig.filename = "CustomerList_" + moment();
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };
  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: true,
    enableHiding: false,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box className="w-100 d-flex tableicons">
        <div className=''>
          <div className=''>Your Request: <strong>{question}</strong></div>
        </div>
        <div className='ms-auto'>
          <div className='bg-wahite border border-end-0 ps-2 py-1 tablebtndown-btn'>
            <Button className="px-0 tablebtndown" onClick={handleExportData} startIcon={<FileDownloadIcon />}></Button>
          </div>
        </div>
      </Box>
    ),
  });

  return (type === 'table') ? <MaterialReactTable table={table} /> : <> <div className=''>
    <div className=''>Your Request: <strong>{question}</strong></div>
  </div><div className='mt-2'>Response: <div className='p-3 rounded-6 border mt-1 bg-light'><strong>{text}</strong></div></div></>;
};

DashboardResult.propTypes = {
  question: PropTypes.string.isRequired,
  results: PropTypes.shape({
    columns: PropTypes.object,
    records: PropTypes.array,
    type: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};


export default DashboardResult;
