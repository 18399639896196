import axios from "axios";
import { alertService } from "../components/GlobalComponent/alertService";
import { API_URL, handleError, FETCH_QUERY_RESULTS, SET_QUERY_QUESTION, ALLOWED_WHITELISTED_DOMAINS, FETCH_QUESTION_HISORY, RESET_QUERY_RESULTS, SET_ASKQUESTION_LOADER } from "../actions/index";
export const setQueryQuestion =
  (question) =>
    async (dispatch) => {
      dispatch({
        type: SET_QUERY_QUESTION,
        payload: question,
      });
    };

export const askQueryQuestion =
  (question, isViewHistory) =>
    async (dispatch) => {
      try {

        dispatch({
          type: SET_ASKQUESTION_LOADER,
          payload: true,
        });

        let ask_funnel_url = `${API_URL}/funnel/askdatafunnel`;
        const parsedUrl = new URL(ask_funnel_url);

        if (ALLOWED_WHITELISTED_DOMAINS.includes(`${parsedUrl.protocol}//${parsedUrl.host}`)) {
          let payload = {
            "question_text": question
          }
          let res;
          // res = await axios.post(ask_funnel_url, payload, {
          //   headers: {
          //     token: token
          //   }
          // });

          // let results = res.data;            
          dispatch({
            type: RESET_QUERY_RESULTS,
            payload: '',
          });

          dispatch({
            type: SET_ASKQUESTION_LOADER,
            payload: false,
          });


          let results = {};
          if (isViewHistory === '0') {
            results = {
              'data': 'The latest stable version of PHP is 8.3.9, released on July 4, 2024. PHP 8.4 is currently in development and scheduled for general availability on November 21, 2024​ (PHP.net)​​ (PHP.Watch)​. You can stay updated on the latest PHP versions and features through the official PHP website and resources like PHP Watch​ (PHP.net)​​ (PHP.Watch)'
            }
          } else {
            results = {
              'data': [
                {
                  'question': 'question 1',
                  'answer': "answer1"
                },
                {
                  'question': 'question 2',
                  'answer': "answer2"
                },
                {
                  'question': 'question 3',
                  'answer': "answer3"
                },
                {
                  'question': 'question 4',
                  'answer': "answer4"
                }
              ]
            }
          }
          dispatch({
            type: FETCH_QUERY_RESULTS,
            payload: results.data,
          });
        } else {
          alertService.error('Domain not allowed', { autoClose: true });
          const error = new Error('Domain not allowed');
          handleError(error, dispatch);
        }

      } catch (error) {
        handleError(error, dispatch);
      }
    };

export const viewHistory =
  (question) =>
    async (dispatch) => {
      try {
        let ask_funnel_url = `${API_URL}/funnel/askdatafunnel`;
        const parsedUrl = new URL(ask_funnel_url);

        if (ALLOWED_WHITELISTED_DOMAINS.includes(`${parsedUrl.protocol}//${parsedUrl.host}`)) {
          let payload = {
            "question_text": question
          }
          let res;
          // res = await axios.post(ask_funnel_url, payload, {
          //   headers: {
          //     token: token
          //   }
          // });

          // let results = res.data;            
          let results = {
            'data': [
              {
                'question': 'What PHP latest version?',
                'answer': "The latest stable version of PHP is 8.3.9, released on July 4, 2024. PHP 8.4 is currently in development and scheduled for general availability on November 21, 2024​ (PHP.net)​​ (PHP.Watch)​. You can stay updated on the latest PHP versions and features through the official PHP website and resources like PHP Watch​ (PHP.net)​​ (PHP.Watch)​."
              },
              {
                'question': 'question 2?',
                'answer': 'The latest stable version of PHP is 8.3.9, released on July 4, 2024. PHP 8.4 is currently in development and scheduled for general availability on November 21, 2024​ (PHP.net)​​ (PHP.Watch)​. You can stay updated on the latest PHP versions and features through the official PHP website and resources like PHP Watch​ (PHP.net)​​ (PHP.Watch)'
              },
              {
                'question': 'question 3',
                'answer': "answer3"
              }
            ]
          }
          dispatch({
            type: FETCH_QUESTION_HISORY,
            payload: results.data,
          });
        } else {
          alertService.error('Domain not allowed', { autoClose: true });
          const error = new Error('Domain not allowed');
          handleError(error, dispatch);
        }

      } catch (error) {
        handleError(error, dispatch);
      }
    };
    