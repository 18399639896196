
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import moment from 'moment-timezone';
import { askUserQuestion, setQuestion } from "../../../actions/index";
import * as routes from "../../../constants/routes";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Progress from "react-progress-2";
import Result from '../Result/Result';
class CustomerBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workStartDate: new Date(),
      WorkStartDateHelperText: "",
      results: {},
      question: "",
      askedQuestion: "",
    };
    if (!sessionStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken") === '') {
      this.props.history.push(routes.LOGIN);
    }

  }

  componentDidMount() {

    let currentUser = this.props.currentUser;
    if (currentUser === '' || Object.keys(currentUser).length === 0) {
      window.location.href = '/';
    }

  }

  componentDidUpdate(prevProps) {

    if (this.props.askResults && prevProps.askResults !== this.props.askResults) {
      this.setState({
        // results: {
        //   records: this.props.askResults.records,
        //   columns: this.props.askResults.columns
        // },
        results: this.props.askResults,
        question: ''
      });
    }
    if (this.props.questionObj && prevProps.questionObj !== this.props.questionObj) {
      this.setState({
        askedQuestion: this.props.questionObj
      });
    }
  }
  onChangeWorkDate(val) {
    let startDate = moment(val).format('YYYY-MM-DD');
    this.setState({ workStartDate: startDate, WorkStartDateHelperText: "", question: '', results: {} });
  }
  handleKeyDown(event) {
    if (event.key === 'Enter') {
      this.sendQuestion();
    }
  }

  onChangeValueHandler = (e) => {
    let value = e.target.value;
    this.setState({
      [e.target.id]: value,
    });
  };
  sendQuestion = () => {
    let workStartDate = this.state.workStartDate;

    let question = this.state.question;
    if (question && question !== '') {
      question = question.trim();
    }
    let token = this.state.token;

    if (!Progress.isVisible() && question !== '') {
      this.setState({
        results: {
          records: [],
          columns: {}
        }
      });
      this.props.setQuestion(question);
      this.props.askUserQuestion(question, token);
    }

  }

  render() {

    return (
      <div className="flex-grow-1 pb-5 px-3">
        <div className="text-center mt-3">
          <img src="/images/logo-symbol.png" className="" alt="send" />
          <h1 className="font-24 font-blue fw-bold my-2">BlattnerTech India</h1>
          <h2 className="font-32 text-dark fw-bold my-2">Customer/User Retriever Engine</h2>
          <p className="fw-semibold font-16 text-muted">Identify BlattnerTech India team members by skillset and project availability</p>
        </div>

        <div className="w-100 max-w-896 mx-auto">
          {this.state.WorkStartDateHelperText}
          <div className="input-group mb-3 searchbox rounded-6 mt-2 mb-3">
            <input type="" id="question" value={this.state.question} className="form-control bg-transparent border-0" placeholder="Make a resource request" onChange={(e) => this.onChangeValueHandler(e)} onKeyDown={(e) => this.handleKeyDown(e)} autoComplete="off" />
            <button className="input-group-text enter-text-button bg-transparent border-0" disabled={!this.state.question} onClick={() => this.sendQuestion()}>
              <img src="/images/send_black_dark.png" className="" alt="send" />
            </button>
          </div>
          {Object.values(this.state.results).length > 0 &&
            <div className="resulttable">
              <Result question={this.state.askedQuestion} results={this.state.results}></Result>
            </div>
          }
        </div>
      </div>
    );
  }
}

CustomerBoard.propTypes = {
  askResults: PropTypes.object, // Validate history prop
  history: PropTypes.object.isRequired, // Validate history prop
  currentUser: PropTypes.object.isRequired, // Validate currentUser prop
  questionObj: PropTypes.string,
  setQuestion: PropTypes.func,
  askUserQuestion: PropTypes.func
};

function mapStateToProps(state) {
  return {

    currentUser: state.security.user,
    askResults: state.dashboard.askResults,
    questionObj: state.dashboard.questionObj,
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      setQuestion,
      askUserQuestion
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerBoard);