import React from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';
import { BarChart } from "react-chartjs-library";
import { fontStyleForChart } from "./helper";

class BarChartComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        data: [],
        dataSets: [],
        labelArray: [],
        forecastData: [],
        datakey: ["utilization"]
      };
    }

    componentDidMount() {
        this.processData(this.props?.info || []);
    }
    
    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.info, this.props.info)) {
          this.processData(this.props?.info || []);
        }
    }

    processData() {
        // Sort the array by the "team" property in ascending order
        const dataArr = _.sortBy(this.props?.info, item => item.team.toLowerCase());
        const labelArr = dataArr.map(ele => ele.team);
        const utilizationData = dataArr.map(ele => ele.utilization);
        const selectedTeam = this.props.team;
        
        const dataValues = [{
          label: (selectedTeam !== 'all') ? 'Employee Utilization': 'Team Utilization',
          data: utilizationData,
          backgroundColor: "#0075FD",
        }];

        this.setState({
            data: dataArr,
            dataSets: dataValues,
            labelArray: labelArr,
            forecastData: dataValues
        });

        this.decimals = 0;
        this.options = {
            plugins: {
                title: {
                    display: true,
                    text: (selectedTeam !== 'all') ? 'Employee Utilization Report' : 'Team Utilization Report',
                    align: 'start',
                    font: {
                        size: 16,
                        color: '#212529',
                        weight: '400',
                        family: fontStyleForChart
                    },
                    padding: {
                        bottom : 20,
                    }
                },
                legend: {
                    display: false,
                    labels: {
                        filter: (legendItem, data) => data.datasets[0].data[legendItem.index] !== 0,
                        font: {
                          size: 16,
                          color: '#212529',
                          weight: '400',
                          family: fontStyleForChart
                        },
                    }
                },
                datalabels: {
                    display: true,
                },
                tooltip: {
                    filter: tooltipItem => tooltipItem.dataset.data[tooltipItem.dataIndex] > 0,
                    callbacks: {
                        label: (tooltipItem, data) => {
                            if (tooltipItem.dataset.label.trim() === 'Team Utilization') {
                                return 'Team Utilization: ' + (tooltipItem.parsed._stacks.y[0]) + '%'
                            } else if(tooltipItem.dataset.label.trim() === 'Employee Utilization') {
                                return 'Employee Utilization: ' + (tooltipItem.parsed._stacks.y[0]) + '%'
                            } else {
                                return tooltipItem.dataset.label + ": " + (tooltipItem.formattedValue)+ "%"
                            }
                        },
                        footer: function (items) {
                            //return 'Total: $' + (items.reduce((a, b) => a + b.parsed.y, 0)).toFixed(2)
                        }
                    }
                }
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        font: {
                            size: 8,
                            weight: '600',
                            family: fontStyleForChart
                        }
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        callback: (value) => {
                            return value.toFixed(this.decimals) + '%';
                        },
                        font: {
                            size: 10,
                            weight: '600',
                            family: fontStyleForChart
                        }
                    }
                },
            },
            maintainAspectRatio: false,
            barThickness: 20, // Set the width of the bar lines
        };
    }

    render(){
      const { dataSets, labelArray } = this.state;
      const dataVal = {
          labels: labelArray,
          datasets: dataSets
      };

      return (
        <div id="bar-chart" className="ratio ratio-1x1">
            {this.state.forecastData !== null && this.state.forecastData.length > 0 && (
                <Bar options={this.options} data={dataVal} />
            )}
        </div>
      );
    }
};

BarChartComponent.propTypes = {
    info: PropTypes.array.isRequired, // Validate history prop
    team: PropTypes.string.isRequired, // Validate history prop
};

export default BarChartComponent;
