import { SET_QUERY_QUESTION, FETCH_QUERY_RESULTS, FETCH_QUESTION_HISORY, RESET_QUERY_RESULTS, SET_ASKQUESTION_LOADER } from "../actions/index";

const INITIAL_STATE = {
  queryQuestion: '',
  queryResult: '',
  historyChat: [],
  isViewHistory: '0',
  showQuestionLoader:false,
};
export default function queryboxReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_QUERY_QUESTION:
      return {
        ...state,
        queryQuestion: action.payload,
      }
    case FETCH_QUERY_RESULTS:
      return {
        ...state,
        queryResult: action.payload,
        historyChat: '',
        isViewHistory: '0'
      }
    case RESET_QUERY_RESULTS:
      return {
        ...state,
        queryResult: '',
      }
    case FETCH_QUESTION_HISORY:
      return {
        ...state,
        historyChat: action.payload,
        isViewHistory: '1'
      }
    case SET_ASKQUESTION_LOADER:
      return {
        ...state,
        showQuestionLoader: action.payload,
      }
    default:
      return state;
  }
}
