
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import * as routes from "../../../constants/routes";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import QueryResult from '../queryResult/QueryResult';
import QueryQuestion from '../queryResult/QueryQuestion';
class ChatHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryQuestion: '',
      historyChat: [],
      isViewHistory: ''
    };
    if (!sessionStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken") === '') {
      this.props.history.push(routes.LOGIN);
    }

  }

  componentDidMount() {

    let currentUser = this.props.currentUser;
    if (currentUser === '' || Object.keys(currentUser).length === 0) {
      window.location.href = '/';
    }    
    if (this.props.queryQuestion) {
      this.setState({
        question: this.props.queryQuestion
      });
    }    
    if (this.props.historyChat) {      
      this.setState({
        historyChat: this.props.historyChat
      });
    }

    if (this.props.queryResult) {     
      this.setState({
        queryResult: this.props.queryResult
      });
    }
    if (this.props.isViewHistory) {
      this.setState({
        isViewHistory: this.props.isViewHistory
      })
    }

  }

  componentDidUpdate(prevProps) {


    if (this.props.queryResult && prevProps.queryResult !== this.props.queryResult) {      
      this.setState({
        queryResult: this.props.queryResult
      });
    }
    if (this.props.queryQuestion && prevProps.queryQuestion !== this.props.queryQuestion) {      
      this.setState({
        question: this.props.queryQuestion
      });
    }
    if (this.props.historyChat && prevProps.historyChat !== this.props.historyChat) {
      console.log("this.props.history:::::", this.props.historyChat);
      this.setState({
        historyChat: this.props.historyChat
      });
    }
    if (this.props.isViewHistory && prevProps.isViewHistory !== this.props.isViewHistory) {
      this.setState({
        isViewHistory: this.props.isViewHistory
      })
    }
  }


  render() {

    return (
      <div className="flex-grow-1 pb-5 px-3">
        <div className="text-center mt-3">
          <img src="/images/logo-symbol.png" className="" alt="send" />
          <h1 className="font-24 font-blue fw-bold my-2">BlattnerTech India</h1>

          {this.state.isViewHistory === '1' ? this.state.historyChat.map((obj, index) => (
            <div className=" w-100 max-w-896 mx-auto container-fluid pt-5">
              <QueryQuestion question={obj.question}></QueryQuestion>
              <QueryResult answer={obj.answer}></QueryResult>
            </div>
          )) : <div className=" w-100 max-w-896 mx-auto container-fluid pt-5">
            <QueryQuestion question={this.state.question}></QueryQuestion>
            <QueryResult  isLastIndex='1'></QueryResult>
          </div>}


        </div>
      </div >
    );
  }
}

ChatHistory.propTypes = {
  historyChat: PropTypes.object.isRequired, // Validate history prop
  currentUser: PropTypes.object.isRequired, 
  queryResult: PropTypes.object.isRequired,
  isViewHistory: PropTypes.object.isRequired,
  queryQuestion: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    currentUser: state.security.user,
    queryQuestion: state.queryBox.queryQuestion,
    historyChat: state.queryBox.historyChat,
    queryResult: state.queryBox.queryResult,
    isViewHistory: state.queryBox.isViewHistory
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatHistory);