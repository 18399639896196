import { FETCH_ASK_RESULTS, SET_LEFT_MENU, SET_QUESTION,FETCH_FUNNEL_RESULTS,SET_FUNNEL_QUESTION } from "../actions/index";

const INITIAL_STATE = {
  applicationId: "",
  leftMenuOpen: true,
  funnelResult:{},
  funnelQuestion:"",
};
export default function dashboardReducer(state = INITIAL_STATE, action) {
  switch (action.type) {

    case SET_QUESTION:
      return {
        ...state,
        questionObj: action.payload,
      }
    case FETCH_ASK_RESULTS:
      return {
        ...state,
        askResults: action.payload,
      }
    case SET_LEFT_MENU: {
      return {
        ...state,
        leftMenuOpen: action.payload,
      }
    }
    case SET_FUNNEL_QUESTION: {
      return {
        ...state,
        funnelQuestion: action.payload,
      }
    }
    case FETCH_FUNNEL_RESULTS:
      return {
        ...state,
        funnelResult: action.payload,
      }
    default:
      return state;
  }
}
