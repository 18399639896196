import axios from "axios";
import { alertService } from "../components/GlobalComponent/alertService";
import { API_URL, handleError, FETCH_FUNNEL_RESULTS, SET_FUNNEL_QUESTION, ALLOWED_WHITELISTED_DOMAINS } from "../actions/index";
export const setFunnelQuestion =
  (question) =>
    async (dispatch) => {
      dispatch({
        type: SET_FUNNEL_QUESTION,
        payload: question,
      });
    };

export const askFunnelQuestion =
  (question, token,) =>
    async (dispatch) => {
      try {
        let ask_funnel_url = `${API_URL}/funnel/askdatafunnel`;
        const parsedUrl = new URL(ask_funnel_url);

        if (ALLOWED_WHITELISTED_DOMAINS.includes(`${parsedUrl.protocol}//${parsedUrl.host}`)) {
            let payload = {
              "question_text": question
            }
            let res;
            res = await axios.post(ask_funnel_url, payload, {
              headers: {
                token: token
              }
            });

            let results = res.data;

            dispatch({
              type: FETCH_FUNNEL_RESULTS,
              payload: results.data,
            });
        } else {
          alertService.error('Domain not allowed', { autoClose: true });
          const error = new Error('Domain not allowed');
          handleError(error, dispatch);
        }

      } catch (error) {
        handleError(error, dispatch);
      }
    };
