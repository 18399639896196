import axios from "axios";
import moment from 'moment-timezone';
import { API_URL, handleError, FETCH_TEAM_REPORT, FETCH_MONTHLY_REPORT, FETCH_RESOURCE_TEAM_REPORT, FETCH_RESOURCE_MONTHLY_REPORT, FETCH_TEAMS, FETCH_USERMONTHLY_REPORT, FETCH_USER_REPORT, ALLOWED_WHITELISTED_DOMAINS } from "../actions/index";
import { alertService } from "../components/GlobalComponent/alertService";

export const getUtilizationTeamReport =
  (startDate, endDate) =>
    async (dispatch) => {
      try {
        let url = `${API_URL}/utilization/customer-utilization`;
        const parsedUrl = new URL(url);
        if (ALLOWED_WHITELISTED_DOMAINS.includes(`${parsedUrl.protocol}//${parsedUrl.host}`)) {
            let payload = {
              startDate: moment(startDate).format('YYYY-MM-DD'),
              endDate: moment(endDate).format('YYYY-MM-DD')
            }
            const res = await axios.post(url, payload);
            let results = res.data;
            if (results?.data?.user) {
              dispatch({
                type: FETCH_USER_REPORT,
                payload: results?.data?.user || [],
              });
            }
            if(results?.data?.customer) {
              dispatch({
                type: FETCH_TEAM_REPORT,
                payload: results?.data?.customer || [],
              });
            }
        } else {
          alertService.error('Domain not allowed', { autoClose: true });
          const error = new Error('Domain not allowed');
          handleError(error, dispatch);
        }

      } catch (error) {
        handleError(error, dispatch);
      }
    };
export const getUtilizationMonthlyReport =
  () =>
    async (dispatch) => {
      try {
        let url = `${API_URL}/utilization/customer-duration`;
        const res = await axios.get(url);
        let results = res.data;
        if (results?.data?.user) {
          dispatch({
            type: FETCH_USERMONTHLY_REPORT,
            payload: results?.data?.user || [],
          });
        }
        if(results?.data?.customer) {
          dispatch({
            type: FETCH_MONTHLY_REPORT,
            payload: results?.data?.customer || [],
          });
        }

      } catch (error) {
        handleError(error, dispatch);
      }
    };

export const getResourceUtilizationReportTeamWise =
  (startDate, endDate, team) =>
    async (dispatch) => {
      try {
        const url = `${API_URL}/utilization/employee-team-wise`;
        const parsedUrl = new URL(url);
        if (ALLOWED_WHITELISTED_DOMAINS.includes(`${parsedUrl.protocol}//${parsedUrl.host}`)) {
            const payload = {
              startDate: moment(startDate).format('YYYY-MM-DD'),
              endDate: moment(endDate).format('YYYY-MM-DD'),
              team: team || 'all'
            }
            const res = await axios.post(url, payload);
            dispatch({
              type: FETCH_RESOURCE_TEAM_REPORT,
              payload: res.data,
            });
        } else {
          alertService.error('Domain not allowed', { autoClose: true });
          const error = new Error('Domain not allowed');
          handleError(error, dispatch);
        }
      } catch (error) {
        handleError(error, dispatch);
      }
    };


export const getResourceUtilizationMonthlyReport =
  (team) =>
    async (dispatch) => {
      try {
        team = team || 'all';
        const encodedTeam = encodeURIComponent(team);
        const url = `${API_URL}/utilization/employee-month-comparison?team=${encodedTeam}`;
        const res = await axios.get(url);
        dispatch({
          type: FETCH_RESOURCE_MONTHLY_REPORT,
          payload: res.data,
        });

      } catch (error) {
        handleError(error, dispatch);
      }
    };
export const getAllTeams =
  () =>
    async (dispatch) => {
      try {
        const url = `${API_URL}/utilization/employee-teams`;
        const res = await axios.get(url);
        const results = res.data;
        dispatch({
          type: FETCH_TEAMS,
          payload: results?.data || [],
        });

      } catch (error) {
        handleError(error, dispatch);
      }
    };

export const getResourceUtilizationReport =
    (startDate, endDate, team) =>
      async (dispatch) => {
        try {
  
          const url = `${API_URL}/utilization/employee-report`;
          const parsedUrl = new URL(url);
          if (ALLOWED_WHITELISTED_DOMAINS.includes(`${parsedUrl.protocol}//${parsedUrl.host}`)) {
              const payload = {
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
                team: team || 'all'
              }
              const res = await axios.post(url, payload);
              return res.data;
          } else {
            alertService.error('Domain not allowed', { autoClose: true });
            return false;
          }
        } catch (error) {
          handleError(error, dispatch);
        }
      };