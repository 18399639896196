import React, { Component } from "react";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { auth, microsoftProvider } from '../../../firebase';
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setCurrentUser } from "../../../actions/securityActions";
import * as routes from "../../../constants/routes";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      errors: {},
      validLoginInfo: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (Object.keys(this.props.currentUser).length > 0) {
      this.props.history.push(routes.DASHBOARD);
    }

  }
  async componentDidUpdate(prevProps) {
    if (
      this.props.security.user &&
      prevProps.security.user !== this.props.security.user
    ) {
      this.props.history.push(routes.DASHBOARD);
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    setTimeout((e) => {
      if (this.state.username === "" || this.state.password === "") {
        this.setState({ validLoginInfo: false });
      } else {
        this.setState({ validLoginInfo: true });
      }
    }, 200);
  }

  onSubmit(e) {
    e.preventDefault();
    let email = this.state.username;
    let password = this.state.password;
    signInWithEmailAndPassword(auth, email, password).then(async (res) => {
      let accessToken = res.user.accessToken;
      sessionStorage.setItem("jwtToken", "Bearer " + accessToken);
      this.props.setCurrentUser(accessToken);
    })
      .catch((error) => {
        let errors = { errorMessage: "Invalid email or password" };
        this.setState({ errors: errors });
      });
  }
  
  forgotPasswordHandler(){
    this.props.history.push(routes.FORGOT_PASSWORD);
  }

  signInWithMicrosoft = async () => {
    try {
      await setPersistence(auth, browserLocalPersistence);
      const res = await signInWithPopup(auth, microsoftProvider);
      const accessToken = res.user.accessToken;
      sessionStorage.setItem("jwtToken", "Bearer " + accessToken);
      this.props.setCurrentUser(accessToken);
    } catch (error) {
      console.error(error);
      let errors = { errorMessage: "Invalid email or password" };
      this.setState({ errors: errors });
    }
  };
  render() {
    const { errors } = this.state;
    return (
      <div className="login-bg position-relative">
        <section className="user-section item-center position-relative w-100" style={{ minHeight:'100vh'}}>
          <div className="p-3 p-md-5 m-2 radius-12 shadow text-center bg-white">
            <figure className="brand-ui">
              <img src="/images/Logo2.png" alt="PTP" style={{ maxHeight: 191 }} />
            </figure>
            <h1 className="font-bold font-32 font-blue">Welcome Back</h1>
            <h2 className="font-bold font-20 mb-3">Sign In to Continue</h2>
            <div>
              <div className="error-msg-holder">
                {errors.errorMessage && (
                  <div className="error text-danger mt-2">{errors.errorMessage}</div>
                )}
              </div>
              <form onSubmit={this.onSubmit} className="d-flex flex-column">
                <div className="form-card my-3">
                  <input type="text" autoComplete="off" className={classnames("form-control font-18", { "is-invalid": errors.username, })} placeholder="Email Address" name="username" value={this.state.username} onChange={this.onChange} />
                  {errors.username && (
                    <div className="invalid-feedback">{errors.username}</div>
                  )}
                </div>
                <div className="form-card">
                  <input type="password" autoComplete="off" className={classnames("form-control font-18", { "is-invalid": errors.password, })} placeholder="Password" name="password" value={this.state.password} onChange={this.onChange} />
                  {errors.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )}
                </div>
                <button  onClick={()=>this.forgotPasswordHandler()} className="text-muted mt-2 text-end text-decoration-none" style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer' }}>Forgot Password?</button>
                <button type="submit" disabled={!this.state.validLoginInfo} className={`btn bg-primary-theme text-white my-3 ${this.state.validLoginInfo ? "active" : ""}`}>Sign in</button>
              </form>
            </div>
            <div className="google-login mt-2">
              
              <button
                className="login-provider-button py-2 px-3 border-0 mx-auto text-center rounded d-flex align-items-center ml-sm-1 justify-content-center mt-2 mt-sm-0"
                onClick={this.signInWithMicrosoft} style={{ minWidth: 230 }}
              ><img
                  src="images/Microsoft_icon.svg"
                  alt="PTP"
                  className="me-2"
                  style={{ maxHeight: 20 }}
                />Sign in with Microsoft
              </button>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Login.propTypes = {
  security: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  setCurrentUser: PropTypes.func.isRequired,
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  security: state.security,
  ptpuser: state.security.ptpuser,
  errors: state.errors,
  currentUser: state.security.user,
  config: state.security.config
});

export default connect(mapStateToProps, { setCurrentUser })(Login);
