export const LANDING = "/";
export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgotpassword";
export const DASHBOARD = "/dashboard";
export const DASHBOARDRESULT = "/dashboardresult/";
export const USERS = "/users";
export const UTILIZATION = "/customer-utilization";
export const CUSTOMERUSAGE = "/customerusage";
export const RESOURCE = "/resource";
export const CUSTOMERINQUIRE = "/customerinquire"
export const RESOURCE_UTILIZATION = "/resource-utilization";
export const FUNNELINQUIRE = "/funnelinquire";
export const CHATHISTORY = "/chat-history"
